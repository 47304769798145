export const appData = 
  {
    suggestedDescriptors: [
      "foraging",
      "streetart",
      // "publicart",
      "edgelands",
      "beach",
      "outskirts",
      "heritage",
      "allotments",
      "woodland",
      "water",
      "texture",
      "floral",
      "nature",
      "urban",
      "rural",
      // "citycentre",
    ]
  }